
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import API from "@/api/API";
import CreateBeneficiary from "@/modules/orders/components/CreateBeneficiary.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import CommentsPreview from "@/modules/orders/components/CommentsPreview.vue";
import Payments from "@/modules/orders/components/Payments.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import Allocation from "@/modules/orders/components/Allocation.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import CommonActions from "@/modules/orders/mixins/common_actions";
import SelectTemplateDialog from "@/modules/orders/components/dialogs/SelectTemplateDialog.vue";
import ContentEditor from "@/components/form-components/ContentEditor.vue";
import Services from "@/modules/orders/components/Services.vue";
import Client from "@/modules/orders/mixins/client";
import Allocations from "@/modules/orders/mixins/allocations";
import Comments from "@/modules/orders/mixins/comments";
import Permissions from "@/modules/orders/mixins/permissions";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    Services,
    ContentEditor,
    SelectTemplateDialog,
    FileUploader,
    Allocation,
    Payments,
    CommentsPreview,
    DatePicker,
    CreateBeneficiary,
    ClientData,
    Documents
  },

  mixins: [CommonActions, Client, Allocations, Comments, Permissions],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentStatusAlias: {
      type: String,
      required: true,
      default: ""
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    selectedField: "" as string,
    powerOfAttorney: null as any,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "operator",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any,
      details: {
        services: [] as Array<any>
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      areas: ["agro", "other"] as Array<string>,
      reasons: [] as Array<SelectComponentInterface>,
      specialists: [] as Array<SelectComponentInterface>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 2,
    totalSum: 0 as number,
    createBeneficiaryModal: false as boolean,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    loadingSaveDocument: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    comments: {} as any,
    oldModel: null as any,
    forceKey: 0 as number,
    scanned_documents: [] as Array<any>,
    advice: null as any,
    content: null as any,
    showSelectTemplateDialog: false as boolean,
    hasContent: false as boolean,
    paymentsTotalPrice: 0 as number,
    dialog: false
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
        this.oldModel = JSON.parse(JSON.stringify(this.model));

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 0;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (
          this.model.documents.some((item: any) => item.file_type === "advice")
        ) {
          this.advice = this.model.documents.find(
            (item: any) => item.file_type === "advice"
          ).file;
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    getAdviceError() {
      const index = this.model.documents.findIndex(
        (item: any) => item.file_type === "advice"
      );
      if (index !== -1) {
        return this.errorMessages[`documents.${index}.file`];
      }
      return null;
    },
    canChangedReleaseMode() {
      return this.oldModel?.order.release_mode === "urgent";
    },
    showUploadNoticeTab() {
      return this.model.order?.status?.alias === "paid";
    },
    showNoticeUploadText() {
      if (this.model.order?.status?.alias === "issued" && !this.advice) {
        return true;
      }
      return ["paid", "confirmed", "processing"].includes(
        this.model.order?.status?.alias
      );
    },
    showUploadedNotice() {
      return this.model.order?.status?.alias === "issued" && this.advice;
    },
    isDataChanged() {
      if (this.model && this.oldModel) {
        return JSON.stringify(this.model) !== JSON.stringify(this.oldModel);
      }
      return false;
    },
    isOriginalDocument() {
      return this.model.order.document_type === "new";
    },
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    },
    areas() {
      return this.selects.areas.map((item: any) => ({
        value: item,
        text: this.$t(`orders.areas.${item}`, this.model.order.language)
      }));
    },
    isBeneficiaryAndProcessing() {
      return (
        this.model.order.status.id === 1 && this.model.order.source === "client"
      );
    },
    orderFulfilled() {
      return this.model.order.status.id === 4;
    },
    statusChanged() {
      return this.currentStatusAlias !== this.model.order.status.alias;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isBookkeeper() {
      return this.currentRole.alias === "bookkeeper" || this.currentRole.alias === 'bookkeeper_chief';
    }
  },

  async mounted() {
    await this.loadData();
    await this.loadDocumentContent();
  },

  methods: {
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },
    isSelectBeneficiary(): boolean {
      return !this.model.order.client_id;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const response = await Promise.all([
          API.purposeOfUse().getList(this.model.order.service_type_id, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.model.order.service_type_id),
          API.orders().getLanguages()
        ]);
        this.scanned_documents = await API.orders().getScannedDocuments(
          Number(this.$route.params.id)
        );

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }
          const model = { ...this.model };

          model.details.services = model.details.services.filter(
            (item: any) => !item._isDeleted
          );
          model.order.client_id = model.order.client.id;
          model.order.document_valid_until = this.$moment(
            this.model.order.document_valid_from,
            "YYYY-MM-DD"
          )
            .add(1, "years")
            .subtract(1, "days")
            .format("YYYY-MM-DD");

          this.model = await this.$API
            .orders()
            .edit(Number(this.$route.params.id), model);
          this.oldModel = JSON.parse(JSON.stringify(this.model));

          form.updateGuard();
          this.dialog = !this.statusChanged;

          if (!this.dialog) {
            this.$emit("update");
          }
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_save")
          );
        } else {
          this.selectedTab = 1;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length === 1) {
            if (
              e.errors.hasOwnProperty("order") &&
              e.errors.order.hasOwnProperty("required_guaranty_sum")
            ) {
              this.selectedTab = 1;
            }
          } else if (e.errors.length) {
            this.selectedTab = 0;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;

      this.setDefaultTab();
      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    setPurposeDescription() {
      const index = this.selects.purpose.findIndex(
        (item: any) => item.value === this.model.details.purpose_id
      );

      if (index !== -1 && !this.model.details.purpose_description) {
        this.model.details.purpose_description = this.selects.purpose[
          index
        ].description;
      }
    },
    setBeneficiary(event: any) {
      this.selectedClient = { value: event.id, text: event.name };
      this.setClient(event);
    },
    loadScannedDocuments(val: any) {
      this.scanned_documents = val;
    },
    async sendScannedDocuments() {
      try {
        await this.$API
          .orders()
          .sendScannedDocuments(
            Number(this.$route.params.id),
            this.scanned_documents
          );
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_save")
        );
      } catch (e) {
        if (e.message) {
          await this.$store.dispatch("alert/showError", e.message);
        }

        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
      }
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 1;
        return;
      } else {
        this.selectedTab = 3;
      }
    },
    async saveAdvice(): Promise<void> {
      try {
        if (
          !this.model.documents.some((item: any) => item.file_type === "advice")
        ) {
          this.model.documents.push({ file: this.advice, file_type: "advice" });
        } else {
          const index = this.model.documents.findIndex(
            (item: any) => item.file_type === "advice"
          );
          this.model.documents[index].file = this.advice;
        }
        await this.submit();
      } catch (e) {
        await this.$store.dispatch("alert/showError");
        if (e.hasOwnProperty("erorrs")) {
          this.errorMessages = e.errors;
        }
      }
    },
    downloadAdvice() {
      if (!this.advice || !this.advice.url) {
        return;
      }
      const element = document.createElement("a");
      element.setAttribute("href", this.advice.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async printDocument() {
      try {
        const data = await this.$API
          .orders()
          .printAaijOrder(this.model.order.id);
        if (data) {
          this.downloadFile(data);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadDocumentContent(): Promise<void> {
      try {
        const { content } = await API.orders().getDocumentContent(
          this.model.order.id
        );

        this.content = content;
        this.hasContent = !!content;
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
    },
    async saveDocumentContent(): Promise<void> {
      this.loadingSaveDocument = true;
      try {
        const { content } = await this.$API
          .orders()
          .saveDocumentContent(this.model.order.id, {
            content: this.content
          });
        this.content = content;
        this.hasContent = !!content;
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_save")
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadingSaveDocument = false;
    },
    setContent(content: string): void {
      this.content = content;
    },
    toggleSelectTemplate(): void {
      this.showSelectTemplateDialog = !this.showSelectTemplateDialog;
    },
    reload(): void {
      this.$emit("update");
    },
    canEditForm() {
      return this.model.order.status.alias !== "issued";
    }
    // closeConfirm() {
    //   this.dialog = false;
    //   this.$emit("update");
    // }
  }
});
